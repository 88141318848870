<template>
  <v-form  class="form-test-2">
    <v-container>
      <v-row>
        <v-col
          cols="5"
          md="5"
          class="padding-5"
        >
           <v-text-field
                outlined
                color="var(--green-light-01)"
                label="Nombre"
           ></v-text-field>
        </v-col>
     <v-col
          cols="7"
          md="7"
          class="padding-5"
        >
           <v-text-field
                outlined
                color="var(--green-light-01)"
                label="Apellidos"
           ></v-text-field>
        </v-col>


   <v-col
          cols="7"
          md="7"
          class="padding-5"
        >
           <v-text-field
                outlined
                prepend-inner-icon="$email"
                color="var(--green-light-01)"
                label="Email"
           ></v-text-field>
        </v-col>

   <v-col
          cols="5"
          md="5"
          class="padding-5"
        >
           <v-text-field
                outlined
                prepend-inner-icon="$telefono"
                color="var(--green-light-01)"
                label="Telefono"
           ></v-text-field>
        </v-col>



        <v-col
          cols="12"
          md="12"
          class="padding-5"
        >
          <v-textarea
              solo
              rows="4"
              label="Escribe aquí tu mensaje"
          ></v-textarea>
        </v-col>
         <v-col cols="12" sm="12" class="padding-5">
              <v-layout>
                <v-checkbox
                  class="shrink pt-3 mt-0"
                  off-icon="$checkboxNo"
                  on-icon="$checkboxSi"
                  v-model="checkbox"
                   :rules="checkRules"
                >
                        <template v-slot:label>
                          <div>
                            Acepto los
                              <template >
                                <a  @click.stop="setTerminos2"
                                 class="title-small"
                                style="color: #00EC9E">
                                  Términos y condiciones
                                </a>
                              </template>
                          </div>
                        </template>
                </v-checkbox>
              </v-layout>
            </v-col>
              <v-col cols="12" sm="12" class="center">
              <img @click="onSubmit" class="btn" src="../../assets/768x1024/Boton_Enviar.png" />     
            </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
import DialogTerminos from "./DialogTerminos.vue";
import {checkValidation} from"@/utils/validation";
export default {
    
    data: () => ({
          terminos: false,
          checkbox:false,
          checkRules: checkValidation
    }),
    props:[
        'fields','textArea','sizeClass','title','spanTitle','titleEnd','subtitle','titleClass'
    ],
    methods:{
        onSubmit(e){
         e.preventDefault();
        if( this.$refs.form.validate() ){
                this.$emit('setData');
                this.$refs.form.reset();
        }

        },
          setTerminos() {
      this.terminos = !this.terminos;
   
    },
    setTerminos2(e) {
    e.preventDefault();       
      this.terminos = !this.terminos;
   
    },
      setCheckbox(value) {
      this.checkbox = value;
    },
    },
    components:{
           DialogTerminos,
    }
}
</script>
<style >
.center-margin{
  margin-left:25px;
  margin-right: 25px;
  margin-top:0px;
  text-align: center;
  
}
.btn{

  width: auto;
  height: 28px;
  object-fit: contain;
 cursor: pointer;
}
.center{
  display: flex;
  justify-content: center;
  align-items: center;
}
.parrafo-title-2{
   font-family: Open Sans;
  font-weight: 100;
  font-size: .8em;
}
.padding-5 {
  padding-left: 4px !important;
  padding-right: 4px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}
.form-test-2{
    border: 3px solid #686868;
      border-radius: 37px;
 max-width: 600px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 30px;
  padding-bottom: 0px;

}
.text-btn-custom{
  font-family:BebasNeue;
  font-size: 19px !important; 
}
.title-3{
  margin-top: 10px;
  padding-bottom:5px;
  font-size:1.1em;
  font-family: Open Sans;
  text-align: center;
  font-weight: 900;
  color: #545252;
}
.title-form {

font-family: Open Sans;
font-size: 1.1rem;
color:#545252;
font-weight: 900;
}
.padding-form{
margin-top: 15px;
margin-left: 0px;
margin-right: 0px;
margin-bottom: 0px;
}

@media  (min-width:760px) {
  .form-test-2{
    border: 3px solid #686868;
      border-radius: 37px;
 max-width: 600px;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 40px;
  padding-bottom: 0px;

}
.parrafo-title-2{
   font-family: Open Sans;
    font-weight: 100;
  font-size: 1.3em;
}
.title-3{
  margin-top: 30px;
  padding-bottom:10px;
  font-size:1.6em;
  font-family: Open Sans;
  text-align: center;
  font-weight: 900;
  color: #545252;
}

}
@media (min-width: 1024px) {
  .btn{

  width: auto;
  height: 35px;
  object-fit: contain;
 cursor: pointer;
}
  .form-test-2{
    border: 3px solid #686868;
      border-radius: 37px;
   max-width: 470px;
   max-height:500px;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 30px;
  padding-bottom: 0px;

}
  .title-form {
line-height: .5;
font-family: Open Sans;
font-size: 1.4rem;
color:#545252;
font-weight: 900;
}
.padding-form{
margin-top: 15px;
margin-left: 0px;
margin-right: 0px;
margin-bottom: 0px;
}
  .title-3{
  margin-top: 30px;
  padding-bottom:10px;
  font-size:1.8em;
  font-family: Open Sans;
  text-align: center;
  font-weight: 900;
  color: #545252;
}
  .parrafo-title-2{
   font-family: Open Sans;
  line-height:1;
  font-size: 1.1em;
}
  .form-test{
  
  margin-left: 70px;
  }
  .padding-form{
margin-top: 40px;
margin-left: 90px;
margin-right: 90px;
}
.btn{
  width: auto;
  height: 47px;
  object-fit: contain;
 cursor: pointer;
}
}
</style>