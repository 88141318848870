<template>
    
    <div  class="flex-item-row"> 
        <p class="title-2"> Ayuda</p>

        <p class="paragraph-1" style="max-width:730px;text-align:center"><span class="green-weight">Queremos escucharte.</span> Si tienes dudas, preguntas o comentarios que 
                    nos ayuden a ofrecerte un mejor servicio, contáctanos a través
                    de este formulario.</p>
        <div class="contianer-form">
          <FormAyuda
              :fields="fields"
              title=" "
              :textArea="textArea"
              @setData="registrarInversionista()"
              contenedorTitle="center-margin"
              sizeClass="form-test"
              />
                <img class="img-form" src="../assets/animacion/12/Yipi_AnimacionWeb_12.gif"/>
     
      </div>
        
    </div>
</template>

<script>
import FormAyuda from "../components/commons/FormTextArea.vue";
import {
  emailValidation,
  nombreValidation,
  telefonoValidation,
  apellidosValidation,
} from "@/utils/validation";
export default {
    data: () => ({
  checkbox: false,
    valid: true,
    dialog: false,
    dialogMensaje: "",
    dialogTitle: "",
    inversionistaData: {
      nombre: "",
      apellidos: "",
      telefono: "",
      correo: "",
      giro: "",
    },
    textArea:{
          index: 11,
        cols: "12",
        label: "Escribe aquí tu mensaje",
        rules: nombreValidation,
        vmodel: "",
      
    },
    fields: [
      {
        cols: "6",
        label: "Nombre",
        rules: nombreValidation,
        vmodel: "",
        index: 1,
      },
      {
        cols: "6",
        label: "Apellidos",
        rules: apellidosValidation,
        vmodel: "",
        index: 2,
      },

    {
        cols: "7",
        label: "Email",
        rules: emailValidation,
        icon: "$email",
        vmodel: "",
        index: 4,
      },
      {
        cols: "5",
        label: "Teléfono",
        rules: telefonoValidation,
        icon: "$telefono",
        vmodel: "",
        index: 3,
      },
    
    ],
}),
    components: {

    FormAyuda,
    }
}
</script>
<style scoped>
.img-form{
  position:relative;
  right:0px; 
  height:370px;
  width:auto;
  object-fit: contain;
  display: none;
}
.contianer-form{
display:flex; 
  flex-wrap: wrap;
flex-direction: column-reverse;
}

  .title-6{
    font-size: 2.7rem;
    font-weight: 900;
     color: #00E882;
       font-family: "Open Sans";

  }
  @media  (min-width:1024px) {
    .img-form{
    display: initial;
    }
      
  }
  @media  (min-width:1024px) {

.contianer-form{
display:flex; 
flex-wrap:no-wrap;
flex-direction: initial;
}
.img-form{
  position:relative;

  right:30px; 
  height:500px;
  width:auto;
  object-fit: cover;
}
  }
</style>

